import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const TermsAndConditions = () => (
  <Layout>
    <SEO title="Terms and Conditions" />
    <div className="row column">
      <p>
        <p>
          <strong>Surplus Solutions LLC Auctions</strong>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>TERMS OF USE</strong>
        </p>
        <p>
          By Registering as a buyer or a user regardless of your physical
          location, you agree that: (1) you have read these Terms of Use,
          together with our privacy policy at
          https://www.ssllc.com/privacy-policy (the “Terms” or the “Agreement”);
          (2) you understand these Terms; and (3) you are bound by these Terms
          which govern your use of Surplus Solutions LLC’s (“SSLLC,”“us,” “our,”
          or<span> “we”</span>) “Services” and its website located at
          www.ssllc.com (the “Site”).{" "}
        </p>
        <p>
          These Terms shall apply to every bid or purchase by any party (“Buyer”
          or “Bidder”) either directly from SSLLC or as agent for a third party
          owner (a “Consignor”) of any machinery, equipment, merchandise and
          other items (the “Goods”), or in a transaction in which SSLLC serves
          as broker, agent, liquidator or auctioneer for any third party owner
          (the “Services”). The Terms apply to all such Services.IF YOU DO NOT
          AGREE TO THESE TERMS, DO NOT USE THE SITE AND DO NOT PARTICIPATE IN
          ANY AUCTION. We may revise and update these Terms from time to time in
          our sole discretion. All changes are effective immediately when we
          post them, and apply to all access to and use of the Site thereafter.
          Your continued use of our Services following the posting of revised
          Terms means that you accept and agree to the changes. You are expected
          to check this page from time to time so you are aware of any changes,
          as they are binding on you.
        </p>
        <p>
          You agree that SSLLC may in its sole discretion and at any time
          terminate your access to and use of the Site, or any part thereof,
          with or without notice. You further agree that use of the Site and any
          of SSLLC’s Service shall be immediately terminated if you violate
          these Terms. In addition, SSLLC reserves the right, in its sole
          discretion, to modify or discontinue the Service or any portion
          thereof, with or without notice, and without liability to you.
        </p>
        <p>
          <strong>TERMS AND CONDITIONS</strong>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          1. No Warranty. All Goods are sold on an “AS IS/WHERE IS/WITH ALL
          FAULTS” basis without any warranties or representations of any kind,
          either expressed or implied. Neither SSLLC nor the Consignor makes any
          warranties or representations of any kind or nature with respect to
          the Goods, their condition or their value and in no event shall either
          be responsible for correctness of description, genuineness,
          attribution, provenance, authenticity, authorship, completeness,
          condition of the property or estimate of its value. No statement (oral
          or written) in the catalog, at the auction, or elsewhere shall be
          deemed such a warranty or representation, or any assumption of
          responsibility. Before you decide to use the Services, you should
          conduct whatever investigation and due diligence that you deem
          necessary related to the Goods. Personal on-site inspection of the
          Goods is strongly recommended, and you are advised to independently
          verify all information you deem important. You may contact SSLLC
          directly for detailed information regarding any Goods for sale.
          WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, THE WARRANTIES OF
          FITNESS FOR A PARTICULAR PURPOSE AND MERCHANTABILITY ARE EXPRESSLY
          DISCLAIMED.
        </p>
        <p>&nbsp;</p>
        <p>
          The information presented on or through the Site is made available
          solely for general information purposes. We do not warrant the
          accuracy, completeness or usefulness of this information. Any reliance
          you place on such information is strictly at your own risk. We
          disclaim all liability and responsibility arising from any reliance
          placed on or use of such materials by you or any other visitor to the
          Site, or by anyone who may be informed of any of its contents.
        </p>
        <p>&nbsp;</p>
        <p>
          This Site may include content provided by third parties, including
          materials provided by other users, bloggers and third-party licensors,
          syndicators, aggregators and/or reporting services. All statements
          and/or opinions expressed in these materials, and all articles and
          responses to questions and other content, other than the content
          provided by the Company, are solely the opinions and the
          responsibility of the person or entity providing those materials.
          These materials do not necessarily reflect the opinion of the Company.
          We are not responsible, or liable to you or any third party, for the
          content or accuracy of any materials provided by any third parties.
        </p>
        <p>&nbsp;</p>
        <p>
          2. Registration. Auction bidders (“Bidders”) must register prior to
          bidding at any auction sale. To register, all Bidders must provide
          their name, company (if applicable), mailing address, phone number and
          email address (“Bidder Information”) prior to the auction. By
          registering, you represent and warrant that any and all Bidder
          Information is accurate and complete. It is solely the Buyer’s
          responsibility to maintain current and accurate Bidder Information for
          completeness and accuracy. Bidders are required to provide a valid
          Visa, MasterCard or American Express number, together with security
          code, to secure your obligations hereunder. At its discretion, SSLLC
          may charge a registration fee for electronic bidding. The amount of
          this fee will be clearly displayed during the registration process.
        </p>
        <p>
          The Site and the Services are only available to persons with the legal
          capacity to enter into this Agreement and to purchase Goods.Generally,
          the Site is intended for use only by persons over 18 years of age.If
          you are under the age of 18 you should not use this Site.SSLLC may, at
          its sole and absolute discretion, refuse to accept your registration,
          and may, at any time after accepting registration, refuse to permit
          your continuing use of the Site or participation in any auction for
          any reason or no reason at all.
        </p>
        <p>
          By registering as a Bidder and bidding at the Auction, you shall be
          deemed to represent, warrant and agree with respect to Goods you bid
          on that: (a) you have reviewed all due diligence materials related to
          the Goods, you have inspected the Goods, you are familiar and
          satisfied with the physical condition of the Goods and you have
          conducted such investigation of the Goods as you deemed appropriate,
          (b) neither SSLLC nor Consignor, nor any affiliate, agent, officer,
          employee or representative of either of them, has made any verbal or
          written representation, warranty, promise or guarantee whatsoever to
          you, expressed or implied, and in particular, that no such
          representations, warranties, guarantees, or promises have been made
          with respect to the physical condition, operation, or any other matter
          or thing affecting or related to the Goods and/or the offering or sale
          of the Goods, (c) you have not relied upon any representation,
          warranty, guarantee or promise or upon any statement made or any
          information provided concerning the Goods, including but not limited
          to information made available on-line at the Site, in Auction
          advertising, in the Auction catalog or brochure, or provided or made
          available by SSLLC or by Consignor, or their respective affiliates,
          agents, officers, employees or representatives, (d) you have made your
          bid after having relied solely on your own independent investigation,
          inspection, analysis, appraisal and evaluation of the Goods and the
          facts and circumstances related thereto, (e) you have actual authority
          to enter a bid and to purchase the Goods, (f) you have the capacity to
          close any transaction, (g) any information provided or to be provided
          by or on behalf of the Consignor with respect to the Goods including,
          without limitation, all information contained on-line at the Site, in
          Auction advertising, or any other printed or online materials being
          made available to you by Consignor and SSLLC, was obtained from
          Consignor and/or Consignor’s agents, and SSLLC has not made any
          independent investigation or verification of such information, and
          makes no representations as to the accuracy or completeness of such
          information, (h) without limiting the generality of the foregoing,
          SSLLC shall not have any obligation to disclose to any Bidder, and
          shall have no liability for its failure to disclose to any Bidder, any
          information known to them relating to any Goods except as may be
          required by law, and (j) SSLLC is not liable or bound in any manner by
          any oral or written statements, representations or information
          pertaining to the Goods, or the operation thereof, made or furnished
          by any dealer, broker, agent, employee, or other person.
        </p>
        <p>
          By participating in an auction or sale, you represent that any bid you
          make constitutes an irrevocable agreement to purchase the Goods for
          the full amount of the bid and that once a winning bid is accepted,
          you are obligated to purchase such Goods for the amount of the High
          Bid.In the event of any dispute among Bidders, or in the event of
          doubt on the part of SSLLC as to the validity of any bid, SSLLC will
          have the final discretion to determine the High Bid, the successful
          Buyer, to cancel the Auction, or to re-offer the subject Goods for
          auction. If any dispute arises after the Auction, SSLLC’s Auction
          record shall determine conclusively all bidding issues, including but
          not limited to the High Bid and the Buyer.
        </p>
        <p>&nbsp;</p>
        <p>
          3. Deposits. SSLLC reserves the right to require a deposit based on
          your projected spending as determined in its reasonable discretion.
          The credit card used for your registration will be charged for any
          deposits required. Please call us at 401-526-0055 within 48 hours of
          the auction closing date, otherwise your card on file will be charged
          for the full amount of your auction purchases. If you are unsuccessful
          in the auction, any deposit will be refunded on your card within two
          business days following the auction.
        </p>
        <p>
          4. Invoices &amp; Payment Instructions. Successful bidders (each, a
          “Buyer”) will be sent an electronic invoice to the email address
          provided during registration. Invoices can be faxed upon request.
          Detailed payment instructions for each sale are available in the
          printed auction or sale catalog at the sale or auction location on the
          day of the sale or auction. Bidders will be sent detailed payment
          instructions via email along with their invoice.
        </p>
        <p>
          5. Payments. SSLLC must receive the balance of the total purchase
          price no later than the close of business on the business day
          following the auction (the “Final Payment Date”). All payments must be
          cashier’s or certified check, federal wire transfer of immediately
          available funds, corporate check, or Credit Card. Without limiting
          SSLLC’s discretion, no corporate checks will be accepted without a
          bank guarantee and no personal checks will be accepted. Title does not
          pass to Buyer until the purchase price and all Taxes (as defined
          below) have been paid in full to SSLLC.
        </p>
        <p>
          6. Taxes. Buyer must also pay to SSLLC on or before the Final Payment
          Date, all sales, export, use and other taxes due to any federal, state
          or local taxing authority (the “Taxes”) as a result of Buyer's
          purchase, including without limitation, any taxes arising from any
          Buyer's Premium (as defined in Section 7 below). To receive any sales
          tax exemption, Buyer must provide SSLLC with a valid state sales tax
          exemption certificate acceptable in form, scope and substance to SSLLC
          in SSLLC'S sole discretion.
        </p>
        <p>
          7. Buyer’s Premium. SSLLC shall charge and collect from each Buyer a
          Buyer's premium ("Buyer's Premium") on any Goods sold in addition to
          the purchase price as bid. The Buyer's Premium is a percentage that is
          added to the final purchase price of any item. SSLLC makes the Buyer's
          Premium information available in writing electronically or in printed
          format prior to each auction for each of the Goods identified for
          auction or sale.You acknowledge and agree that the Buyer’s Premium is
          deemed earned upon conclusion of the auction or sale.
        </p>
        <p>
          8. Removal of Goods. Unless otherwise agreed in writing, Buyers or
          their authorized agents must remove all Goods purchased before the
          final removal date and time announced by SSLLC at the auction (the
          "Removal Deadline"). No Goods shall be removed until the full purchase
          price and all applicable Taxes thereon have been received by SSLLC in
          the form required herein. ANY ITEMS NOT REMOVED ON OR BEFORE THE
          REMOVAL DEADLINE SHALL BE DEEMED ABANDONED AND SSLLC SHALL HAVE NO
          FURTHER OBLIGATION TO BUYER WITH RESPECT TO SUCH ITEMS. BUYER CONSENTS
          THAT SSLLC MAY DISPOSE OF ANY AND ALL ITEMS NOT PAID FOR AND/OR
          REMOVED BY THE REMOVAL DEADLINE AND MAY RESELL SCRAP, AND/OR OTHERWISE
          DISPOSE OF SUCH ITEMS AS DETERMINED IN ITS SOLE AND ABSOLUTE
          DISCRETION. SSLLC shall have no responsibility whatsoever for any
          missing or lost items that can be removed by hand if such items have
          not been removed within forty-eight (48) hours of the conclusion of
          the sale.{" "}
        </p>
        <p>
          Buyer shall be responsible for the safe and proper removal of any
          Goods purchased by Buyer. SSLLC does not disconnect utilities, remove
          structures, pack, ship, store, crate or rig purchased Goods. Buyers
          may either pick up Goods at the designated auction site personally or
          can contract with an authorized third party agent to manage the
          removal process for them. Before Goods can be removed by an Agent,
          Agent must provide SSLLC with (1) proof of payment in full (Paid In
          Full Invoice) and (2) an "Agent Release Authorization" form which
          allows SSLLC to release items to a third party contractor for
          shipping, rigging, crating or packing purposes.{" "}
        </p>
        <p>
          Goods weighing more than 100 pounds must be removed by a qualified,
          insured rigger approved by SSLLC. Before any removal, Buyer must
          provide SSLLC with a certificate of insurance from the rigger, which
          certificate must be in form, scope and content acceptable to SSLLC in
          SSLLC’S sole discretion. Without limiting SSLLC’S discretion, such
          certificate must show that the rigger carries comprehensive liability
          insurance written by an insurance carrier of national standing and in
          an amount of not less than two million dollars ($2,000,000.00).
          Without limiting the generality of the foregoing, Buyer shall restore
          and repair all real and personal property that is altered or damaged
          as a result of Buyer’s removal of the Goods. SSLLC shall have no
          responsibility to disconnect utilities to the sold asset, including
          electric, gas, waste and water lines. Buyer is solely responsible to
          properly remove and store in appropriate containers all fluids, oils,
          hazardous chemicals, etc., from Goods purchased. Buyer shall defend,
          indemnify and hold harmless SSLLC, the Consignor and the owner of the
          premises in which the Goods are located, from any and all claims,
          damages, and losses arising from Buyer’s or its Agent’s actions with
          respect to the Goods, the removal of the Goods and Buyer’s presence on
          the premises in which the Goods are located, including without
          limitation, injuries to persons or property and the release of
          hazardous materials. Risk of loss on any Goods shall pass to the Buyer
          upon payment in full to SSLLC of all sums due from Buyer in respect to
          the Goods.
        </p>
        <p>
          9. Default. Bidders must bid only on those items they are prepared to
          pay for and remove in accordance with these Terms. ALL BIDS ARE FINAL,
          IRREVOCABLE AND BINDING and DO NOT include any applicable Buyer’s
          Premiums and Taxes. All Goods must be paid for by the Final Payment
          Date and removed by the Removal Deadline. Buyer’s failure to comply
          with these Terms will result in a default being declared and the
          Deposit and Buyer’s Premium may be retained by Seller and/or SSLLC in
          addition to other equitable and legal remedies under applicable law
          all of which are reserved. IN THE EVENT BUYER FAILS TO PERFORM
          CONTRACTUAL OBLIGATIONS (PAYMENT AND REMOVAL) AS SPECIFIED IN THESE
          TERMS, BUYER WILL AUTOMATICALLY BE PLACED IN DEFAULT. THIS IS YOUR
          OFFICIAL NOTICE OF DEFAULT. Upon default, you shall lose all right,
          title, and interest which you might otherwise have acquired in and to
          such Goods as to which default has occurred. SSLLC will dispose of
          such Goods in its sole discretion.
        </p>
        <p>&nbsp;</p>
        <p>
          10. After Buyer has removed any Goods after its purchase neither
          Consignor nor SSLLC shall have any obligation to make any adjustment
          or accommodation to Buyer because of any mistake in quantity, quality,
          character, condition or other defect or problem with respect to such
          Goods. Buyer must make all requests for adjustments in writing to
          SSLLC before any item is removed from the premises where the auction
          is conducted.No returns or refunds will be permitted.
        </p>
        <p>
          11. Damages; Force Majeure. No party shall be responsible for damages,
          losses, delays or failure of performance resulting from unforeseeable
          circumstances reasonably beyond their control, including acts of God,
          strikes, walkouts, riots, acts of war, epidemics, governmental
          regulation, power failures, earthquakes, or other natural disasters.
          <strong>
            {" "}
            SSLLC DOES NOT WARRANT THAT THE FUNCTIONS, FEATURES OR CONTENT
            CONTAINED IN THE SITE, INCLUDING ANY THIRD-PARTY SOFTWARE, PRODUCTS
            OR OTHER MATERIALS USED IN CONNECTION WITH ITS SITE OR ONLINE
            AUCTIONS, WILL BE TIMELY, SECURE, UNINTERRUPTED OR ERROR-FREE, OR
            THAT DEFECTS WILL BE CORRECTED.
          </strong>
        </p>
        <p>
          12. Changes. SSLLC and Consignor reserve the right to withdraw or sell
          any Goods contained in an auction prior to or during the auction
          without notification. SSLLC reserves the right to temporarily or
          permanently end an auction prior to or during the stated auction
          period at is sole discretion without notification. SSLLC, in its sole
          discretion, reserves the right to extend the stated auction period
          without notification. The auctioneer’s announcements at or during the
          Auction take precedence over any and all published materials and these
          Terms. If for any reason SSLLC is unable to deliver any Goods
          purchased by Buyer, SSLLC'S sole liability shall be to return all sums
          paid by Buyer to SSLLC in respect of such Goods.{" "}
        </p>
        <p>
          13. Limitation of Liability. Buyer/Bidder hereby agrees that under all
          possible circumstances, SSLLC's maximum liability in any and all
          auctions and sales transactions is hereby limited to the purchase
          price actually paid by the Buyer for the applicable sale or auction
          transaction. IN NO EVENT WILL SSLLC BE LIABLE FOR ANY DAMAGES
          INCLUDING, WITHOUT LIMITATION, ANY DIRECT, INDIRECT, INCIDENTAL,
          SPECIAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES, LOSSES RELATED
          TO BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION OR LOST PROFITS
          ARISING OUT OF OR IN CONNECTION WITH THE SALE OF THE GOODS OR THE
          AUCTION, OR OUT OF ANY BREACH OF WARRANTY, EVEN IF YOU HAVE BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <p>
          14. Minimum or Reserve Prices. SSLLC and Consignor, in their sole
          discretion, reserve the right to establish a reserve or minimum price
          on any Goods without having to announce, post, disclose or publish
          notice to attendees and Bidders at any auction. SSLLC reserves the
          right to confirm or reject the final bid. Further, SSLLC, and/or its
          affiliates or subsidiaries, may bid at the auction for its own
          account, on behalf of a third party or the Consignor.
        </p>
        <p>
          15. Absentee/Proxy Bids. By completing an “Absentee (Proxy) Bidder
          Form,” Bidders may appoint SSLLC to be an agent and proxy for the sole
          purpose of bidding on the Goods listed when the Bidder CANNOT bid on
          the day of the auction or sale. SSLLC will bid up to the specified
          maximum bid amount on such Goods, using only the amount needed to win
          the item. SSLLC has complete discretion whether and when to accept
          Proxy bids. Proxy bidders will be notified only if they have been
          declared the winning bidder. Notification will be delivered in the
          form of an electronic invoice which will be sent to the email address
          provided during registration.
        </p>
        <p>
          16. Bidding Procedures. SSLLC, in its sole discretion, shall control
          all bidding increments and advances at any auction. SSLLC reserves the
          right to reject any bid that is only a minimal increase over the
          preceding bid, that is not commensurate with the value of the Goods or
          that SSLLC believes was made illegally or in bad faith. In the event
          of a dispute among Bidders, SSLLC may, in its sole discretion, either
          accept what it deems to be the final bid or solicit further bids on
          the item in dispute. SSLLC reserves the right, in its sole discretion,
          to sell Goods advertised as a public auction sale, on a piece-by-piece
          basis or as a complete lot. Without limiting the generality of the
          foregoing, SSLLC may accept a conditional bid on a complete lot and
          then conduct an item by item auction which auction shall be effective
          only if the total proceeds therefrom exceed the amount of the complete
          bid.
        </p>
        <p>
          17. Agency Relationship. When SSLLC is selling or auctioning any Goods
          on behalf of a Consignor, SSLLC shall act as an agent only and shall
          have no liability whatsoever for the acts of the Consignor.
        </p>
        <p>
          18. Export Law Compliance. Buyer hereby agrees to comply with all US
          export and import control and related laws, and acknowledges that
          SSLLC is not the exporter or importer of any purchased item. Certain
          items offered for sale at the auction may constitute "Restricted
          Technology." Under federal law, such items may not be exported outside
          the United States. SSLLC makes no representation or warranty
          concerning, and has conducted no investigation to ascertain which
          items, if any, constitute Restricted Technology, or whether any item
          may have been imported in violation of any U.S. laws or international
          treaties.
        </p>
        <p>
          19. Auction Sites. Buyers acknowledge that an auction site is a
          potentially dangerous place. Flammable, noxious, corrosive and
          pressurized substances may be present. Heavy equipment may be operated
          and electrical circuits may be live. Every person enters the auction
          site at his or her own risk with notice of the condition of the
          premises and the activities that will be or have been conducted on the
          premises. No person shall have any claim against SSLLC, the Consignor,
          the owner of the premises or their respective agents for any injuries
          sustained or for personal injury or damages to or loss of property
          that may occur at the auction site.
        </p>
        <p>
          20. SSLLC Rights and Remedies. If the Buyer fails to comply with any
          of the Terms, SSLLC shall have all rights and remedies available to it
          at law and in equity. Without limiting the generality of the
          foregoing, SSLLC may retain all deposits and partial payments received
          from Buyer and may resell any Goods that Buyer fails to purchase.
          Buyer shall be responsible for all costs, losses, and damages suffered
          by SSLLC (i) as a result of Buyer’s breach of any of the Terms,
          including without limitation, lost profits and consequential damages,
          and (ii) incurred in connection with the enforcement of SSLLC’S
          rights, including, without limitation, legal fees and costs and
          expenses related to the resale of Goods, including storage and rigging
          fees.
        </p>
        <p>
          21. No Collusion. Bidders agree they will not participate in any
          bidding practices designed to manipulate the bidding. Any and all
          forms of collusion between Bidders is forbidden. Bidders agree they
          will not place bids through third parties, using false names or Bidder
          Information.
        </p>
        <p>
          22. Indemnification. You agree that you will defend, indemnify and
          hold harmless SSLLC and any Consignor, including its affiliates,
          directors, officers, employees, agents, shareholders, co-branders, or
          partners, from and against any and all claims, losses, damages,
          liabilities, judgments, fees and expenses incurred by SSLLC or the
          Consignor, including its affiliates, directors, officers, employees,
          agents, shareholders, co-branders, or partners, through the use of the
          Services and the Site.
        </p>
        <p>
          23. Prohibited Uses.You may use the Site only for lawful purposes and
          in accordance with these Terms. You agree not to use the Site:
        </p>
        <p>
          In any way that violates any applicable federal, state, local or
          international law or regulation (including any laws regarding the
          export of data or software to and from the US or other countries).
        </p>
        <p>
          For the purpose of exploiting, harming or attempting to exploit or
          harm minors in any way by exposing them to inappropriate content,
          asking for personally identifiable information or otherwise.
        </p>
        <p>
          To transmit, or procure the sending of, any advertising or promotional
          material, including any “junk mail,” “chain letter” or “spam” or any
          other similar solicitation.
        </p>
        <p>
          To impersonate or attempt to impersonate SSLLC, its employees, another
          user or any other person or entity (including by using email addresses
          or screen names associated with any of the foregoing).
        </p>
        <p>
          To engage in any other conduct that restricts or inhibits anyone’s use
          or enjoyment of the Site, or which, as determined by us, may harm
          SSLLC or users of the Site or expose them to liability.
        </p>
        <p>&nbsp;</p>
        <p>Additionally, you agree not to:</p>
        <p>
          Use the Site in any manner that could disable, overburden, damage or
          impair the site or interfere with any other party’s use of the Site,
          including their ability to engage in real time activities through the
          Site.
        </p>
        <p>
          Use any robot, spider or other automatic device, process or means to
          access the Site for any purpose, including monitoring or copying any
          of the material on the Site.
        </p>
        <p>
          Use any manual process to monitor or copy any of the material on the
          Site or for any other unauthorized purpose without our prior written
          consent.
        </p>
        <p>
          Use any device, software or routine that interferes with the proper
          working of the Site.
        </p>
        <p>
          Introduce any viruses, trojan horses, worms, logic bombs or other
          material that is malicious or technologically harmful.
        </p>
        <p>
          Attempt to gain unauthorized access to, interfere with, damage or
          disrupt any parts of the Site, the server on which the Site is stored,
          or any server, computer or database connected to the Site.
        </p>
        <p>
          Attack the Site via a denial-of-service attack or a distributed
          denial-of-service attack.
        </p>
        <p>
          Otherwise attempt to interfere with the proper working of the Site.
        </p>
        <p>&nbsp;</p>
        <p>
          24. Arbitration; Venue; Prevailing Party.The parties agree to submit
          all controversies, disputes, claims and matters of difference arising
          out of or relating to these Terms and Conditions, including but not
          limited to its enforcement, scope and/or interpretation, exclusively
          to arbitration in Providence County, Rhode Island in accordance with
          the Commercial Arbitration Rules of the American Arbitration
          Association from time to time in effect (the “Arbitration Rules”).The
          parties may agree on a retired judge as sole arbitrator.In the absence
          of such agreement, there will be three arbitrators, selected in
          accordance with the Arbitration Rules.If there are three arbitrators,
          a decision reached by at least two of the three arbitrators will be
          the decision of the arbitration panel.The parties agree to abide by
          all decisions reached and awards rendered in such arbitration
          proceedings, and all such decisions and awards will be final and
          binding on both parties.Judgment upon the award may be entered in any
          court of competent jurisdiction or application may be made to such
          court for a judicial acceptance of the award and an order of
          enforcement.By bidding at an auction, whether present in person, or by
          agent, by proxy, by written bid, telephone bid, internet bid, or other
          means, the Bidder shall be deemed to consent to the jurisdiction of
          the state and federal courts located in Providence County, Rhode
          Island (and of the appropriate appellate courts therefrom) in any such
          action or proceeding (including an action to compel arbitration) and
          waives any objection to venue.Process in any action or proceeding may
          be served personally or by registered mail anywhere in the world.In
          the event of any such arbitration or any permitted court action, the
          prevailing party shall be entitled to reimbursement from the
          non-prevailing party of all reasonable attorney’s fees and
          costs/expenses of the prevailing party and any award of the
          arbitrator(s) or court will include costs and reasonable attorneys’
          fees to the prevailing party.If any Party files a court action arising
          out of or relating to this Agreement or the breach, termination,
          enforcement, interpretation or validity thereof, including the
          determination of the scope or applicability of this agreement to
          arbitrate, to compel or stay arbitration, or to confirm, vacate or
          modify an arbitration award (except for a non-contested application to
          confirm), or to seek payment of any attorneys' fees and/or costs
          awarded by the arbitrator(s) but not paid by the non-prevailing party
          in the arbitration, or in the event any Party seeks enforcement of any
          arbitration award or judgment arising out of an arbitration award,
          reasonable attorney’s fees and other costs incurred by the prevailing
          Party in such court action or in connection with such judgment
          enforcement shall be reimbursed by the non-prevailing Party.THE
          PARTIES UNDERSTAND THAT, ABSENT THIS AGREEMENT, THEY WOULD HAVE THE
          RIGHT TO SUE EACH OTHER IN COURT, AND THE RIGHT TO A JURY TRIAL, BUT
          THEY GIVE UP THOSE RIGHTS VOLUNTARILY AND AGREE TO RESOLVE ANY AND ALL
          GRIEVANCES BY ARBITRATION.
        </p>
        <p>&nbsp;</p>
        <p>
          25. Limitation on Time to File Claims.ANY CAUSE OF ACTION OR CLAIM YOU
          MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS, THE SERVICES OR
          THE SITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF
          ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS
          PERMANENTLY BARRED.
        </p>
        <p>&nbsp;</p>
        <p>
          26. Governing Law.The respective rights and obligations of the parties
          with respect to these Terms, the Site, the Services and the conduct of
          the Auction shall be governed, enforced and interpreted by the laws of
          the state of Rhode Island, without regard for conflicts of law
          principles.{" "}
        </p>
        <p>&nbsp;</p>
        <p>
          27. Licensing. For information about SSLLC’s licensing and bonding,
          please contact SSLLC.
        </p>
        <p>&nbsp;</p>
        <p>
          28. Third Parties.SSLLC and/or Consignor may provide and/or designate
          certain third parties to provide ancillary services in connection with
          a Goods Auction and/or links to the websites or products or services
          of others. Any such designations do not constitute an endorsement by
          SSLLC or Consignor of such third-party service providers, or the
          products, or services of such third parties. These third parties
          operate independently of SSLLC and Consignor and have established
          their own terms and conditions and policies. Bidder acknowledges and
          agrees that SSLLC and Consignor are not responsible for any damages or
          losses caused or alleged to have been caused by the use of any
          Third-Party Services.
        </p>
        <p>&nbsp;</p>
        <p>
          All feedback, comments, requests for technical support and other
          communications relating to the Site should be directed to
          auctions@ssllc.com.
        </p>
        <p>&nbsp;</p>
        <br />
      </p>
    </div>
  </Layout>
)

export default TermsAndConditions
